<template>
    <v-sheet>
        <v-col>
            <h2>Delete Profile Fields</h2>
            <p>Deleting {{profileFieldValues.length}} records</p>

            <p><v-btn @click="del">Delete</v-btn></p>
        </v-col>
        <v-col></v-col>
    </v-sheet>
</template>


<script>
export default {
    name: "DeleteProfileFields",
    computed: {
        profileFieldValues() {
            let questions = Object.values(this.$store.state.profileFieldValues.data);
            return questions
            //.filter( q => q.user == {})
        }
    },
    methods: {
        del(){
            const self = this;
            self.profileFieldValues.forEach( r => {

                self.$store.dispatch("profileFieldValues/delete", r.id)
            })
        }

    },
    created(){
        this.$store.dispatch("profileFieldValues/fetchAndAdd", {clauses: {where: [["user", "==", {} ]] } } )
    }
}
</script>

